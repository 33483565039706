import React from "react"
import { Link } from "gatsby"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Footer = ({district}) => {
  let slugTable = {
    "1": "hwcid-1",
    "2": "hwcid-2",
  }
  return (
    <footer>
  <div className="aside-left">
      <figure>
      {/* <img className="logo" src={logo} alt="HWCID 1 & 2" /> */}
      </figure>
  </div>
  <div className="content">
      { district !== '-1' ? (
        <p>&copy; 2017-{new Date().getFullYear()} Hays County WCID 1 & 2<br />
        <Link to={`/${slugTable[district]}/legal-notices-and-disclaimers`}>Legal Notices & Disclaimers</Link></p>
      ) : (
        <p>&copy; 2017-{new Date().getFullYear()} Hays County WCID 1 & 2<br />
        <Link to={`/legal-notices-and-disclaimers`}>Legal Notices & Disclaimers</Link></p>
      )}
      <p>Website provided by <a target="_blank" rel="noopener noreferrer" href="https://www.touchstonedistrictservices.com/">Touchstone</a></p>
  </div>
  </footer>
)}

export default Footer
